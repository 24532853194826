import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { PlantDocument } from "../services/PlantService";

export class SendDocumentController {
  public constructor(
    private presenter: ISendDocumentPresenter,
    private plantService: PlantGraphQLService
  ) {}

  public mounted(plant: any, document: PlantDocument) {
    this.presenter.mounted(plant, document);
    this.presenter.visibleView = true;
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public selectDocument(documentPath: string) {
    this.presenter.selectDocument(documentPath);
  }

  public removeTableItem(documentPath: string) {
    this.presenter.removeTableItem(documentPath);
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public sendDocuments(plantId: string) {
    FormRequestHandler.handle(
      this.plantService.sendDocuments(
        this.presenter.mailAddress,
        this.presenter.subject,
        this.presenter.text,
        this.presenter.documentPaths,
        plantId
      ),
      response => (this.presenter.sendDocumentsResponse = response),
      "Send documents failed"
    );
  }
}

export interface ISendDocumentPresenter {
  visibleView: boolean;
  sendDocumentsResponse: FormResponse<string>;

  mailAddress: string[];
  subject: string;
  text: string;
  documentPaths: string[];

  mounted(plant: any, document: PlantDocument): void;
  selectDocument(documentPath: string): void;
  removeTableItem(documentPath: string): void;
}
