import { FormResponse } from "@/forms/FormResponse";
import { ISendDocumentPresenter } from "../controllers/SendDocumentController";
import { PlantDocument } from "../services/PlantService";
import { SendDocumentViewModel } from "../vms/SendDocumentViewModel";

export class SendDocumentPresenter implements ISendDocumentPresenter {
  public allDocuments: PlantDocument[] = [];

  public constructor(private vm: SendDocumentViewModel) {}

  public mounted(plant: any, document: PlantDocument) {
    this.allDocuments = plant.plantDocuments;

    this.vm.documents.selected = document.name;
    this.vm.documents.items = plant.plantDocuments.map((el: PlantDocument) => ({
      text: el.name,
      value: el.path
    }));
    const itemIndex = this.vm.documents.items.findIndex(
      el => el.value === document.path
    );
    if (itemIndex >= 0) {
      this.vm.documents.items.splice(itemIndex, 1);
    }
    this.vm.tableItems = [document];

    const plantOperatorMailAddresses = plant.operators
      .map((el: any) => el.eMail)
      .filter((el: any) => !!el);
    const plantContactPersonMailAddresses = plant.contactPersons
      .map((el: any) => el.eMail)
      .filter((el: any) => !!el);
    this.vm.mailAddress.items = [
      ...plantOperatorMailAddresses,
      ...plantContactPersonMailAddresses
    ];
    this.vm.mailAddress.selected = [...plantOperatorMailAddresses];

    this.vm.subject = document.name;

    this.vm.text = `

Mit freundlichen Grüßen

Karl Vavpic
Umweltservice e. U.
Scheiflinger Straße 4
A 9300 St. Veit an der Glan

Tel: +43 / (0) 4212 / 93081
Fax: +43 / (0) 4212 / 93081 - 400

__________________________________________________
Firmenbuchnummer: 305633 k
Gerichtsstand: Klagenfurt
Firmensitz: St. Veit an der Glan
Rechtsform: Einzelunternehmen

Wir garantieren nicht für die Fehler- bzw. Virenfreiheit angehängter Dateien. Für Schäden, die aus der Benutzung der Datei(en) entstehen, übernehmen wir keine Haftung. Insbesondere übernehmen wir auch keine Haftung für entgangene Gewinne, direkte oder indirekte Schäden, materielle oder immaterielle Schäden oder Mängelfolgeschäden, die durch die Daten oder ihren Inhalt verursacht werden. Mit der Verwendung der Daten stimmen Sie diesem Haftungsausschluss zu.
__________________________________________________`;
  }

  public selectDocument(documentPath: string) {
    this.vm.documents.selected = "";
    const itemIndex = this.vm.documents.items.findIndex(
      el => el.value === documentPath
    );
    if (itemIndex >= 0) {
      this.vm.documents.items.splice(itemIndex, 1);
    }

    const documentIndex = this.allDocuments.findIndex(
      el => el.path === documentPath
    );
    if (documentIndex >= 0) {
      this.vm.tableItems.push(this.allDocuments[documentIndex]);
    }
  }

  public removeTableItem(documentPath: string) {
    const itemIndex = this.vm.tableItems.findIndex(
      el => el.path === documentPath
    );
    if (itemIndex >= 0) {
      this.vm.tableItems.splice(itemIndex, 1);
    }

    const documentIndex = this.allDocuments.findIndex(
      el => el.path === documentPath
    );
    if (documentIndex >= 0) {
      this.vm.documents.items.push({
        value: this.allDocuments[documentIndex].path,
        text: this.allDocuments[documentIndex].name
      });
    }
  }

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public get mailAddress() {
    return this.vm.mailAddress.selected;
  }

  public get subject() {
    return this.vm.subject;
  }

  public get text() {
    return this.vm.text;
  }

  public get documentPaths() {
    return this.vm.tableItems.map(el => el.path);
  }

  public set sendDocumentsResponse(response: FormResponse<string>) {
    this.vm.sendMailRequest = response;

    if (!response.loading && !response.error) {
      this.vm.updatedPlant = response.data;
    }
  }
}
