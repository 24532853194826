































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";
import Select from "@/components/form/Select.vue";
import IconButton from "@/components/basic/IconButton.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";

import { SendDocumentViewModel } from "@/plant/vms/SendDocumentViewModel";
import { SendDocumentPresenter } from "@/plant/presenters/SendDocumentPresenter";
import { SendDocumentController } from "@/plant/controllers/SendDocumentController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";

@Component<SendDocumentView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    RequestButton,
    HoverableDatatable,
    Select,
    IconButton,

    ValidatedTextfield,
    ValidatedTextarea,
    ValidatedCombobox
  },
  props: {
    plant: {},
    existingDocument: {}
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.updatedPlant"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  }
})
export default class SendDocumentView extends Vue {
  private vm = new SendDocumentViewModel();
  private controller?: SendDocumentController;

  private initController(): SendDocumentController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SendDocumentController(
      new SendDocumentPresenter(this.vm),
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.plant, this.$props.existingDocument);
  }

  private openPdf(location: string) {
    window.open(location, "_blank");
  }
}
