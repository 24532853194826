import { Selection, Request, TableHeader } from "@/forms/ViewModelFormTypes";
import { PlantDocument } from "../services/PlantService";

export class SendDocumentViewModel {
  public plant: any = {};
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public updatedPlant: string = "";

  public mailAddress: { selected: string[]; items: string[] } = {
    items: [],
    selected: []
  };
  public subject = "";
  public text = "";

  public tableLoading = false;
  public tableHeaders: TableHeader[] = [
    { text: "Name", value: "name" },
    {
      text: "Hochgeladen am",
      value: "uploadedAt"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "160px"
    }
  ];
  public tableItems: PlantDocument[] = [];

  public sendMailRequest: Request = { loading: false, error: "" };
  public documents: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Dokumente"
  };
}
